<template>
  <div class="normal-view">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand text-primary" href="#">A2Z Travel Groups</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link active" to="/">Home</router-link>
            </li>
            <li v-if="bIsAdmin" class="nav-item">
              <router-link class="nav-link active text-success" to="/admin">Admin</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link active" to="/bookings">My Bookings</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link active" to="/cart">My Cart</router-link>
            </li>
            <li v-if="bIsAdmin" class="nav-item">
              <a target="_blank" class="nav-link active" href="https://agents.a2ztravelgroups.com">Agents Portal</a>              
            </li>
            <li class="nav-item">
              <a @click="HandleSignOut" class="nav-link text-danger hover-pointer">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import axios from 'axios'
import {onUpdated, ref, watch, watchEffect} from 'vue'
import router from "@/router";

    export default {

        setup(){ 

          const bIsAdmin = new ref(false);

          axios.get('/api/IsLoggedIn').then((Result) => {
            if(Result.data.Success){
              bIsAdmin.value = Result.data.IsAdmin              
            }
          }).catch((Error) => {
            console.log(Error)
          })
  
          const HandleSignOut = () => {
            axios.get('/api/LogoutUser').then((Result) => {
              if(Result.data.Success){
                router.push({name: 'login'})
              }
            }).catch((Error) => {
              console.log(Error)
            })
          }

          return {HandleSignOut, bIsAdmin}
          
        }
    }
</script>

<style>
.hover-pointer:hover{
  cursor: pointer;
}
</style>