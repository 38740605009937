<template>
  <div class="normal-view">
    <br>

    <div class="card">
      <div class="card-header h3 text-primary">{{Hotels.length}} Hotels Found</div>
      <div class="card-body">
        <div class="row">
          <h5 class="card-title">Itinerary Overview</h5>
        </div>
        <div class="row">

          <div class="col-sm-4">
            <div class="input-group mb-3">
              <span class="input-group-text">Requested Rooms</span>
              <input type="text" readonly :value="Input_NumberRooms" class="form-control">
            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-3">
              <span class="input-group-text">Total Adults</span>
              <input type="text" readonly :value="TotalAdults" class="form-control">
            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-3">
              <span class="input-group-text">Total Childs</span>
              <input type="text" readonly :value="TotalChilds" class="form-control">
            </div>
          </div>
    
        </div>
        <hr>
        <div class="row">

          <h3 class="lead text-dark">Itinerary For: <span v-if="Itinerary.length > 0">{{Itinerary[0].HotelName}}</span></h3>

          <table :class="IsItineraryValid ? 'table table-success' : 'table table-danger'">
            <thead>
              <tr>
                <th>Room</th>
                <th>Board</th>
                <th>Adults</th>
                <th>Children</th>
                <th>Price</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(R, iLoop) in Itinerary" :key="R.rateKey">
                <td>{{R.RoomName}}</td>
                <td>{{R.boardName}}</td>
                <td>{{R.adults}}</td>
                <td>{{R.children}}</td>
                <td>ZAR {{R.net}}</td>
                <td><button @click="HandleRemoveItemFromItin(iLoop)" class="btn btn-sm btn-danger">Delete</button></td>
              </tr>
            </tbody>
          </table>

          </div>

          <div class="row">
            <div class="col-sm-3">
              <button v-if="IsItineraryValid" type="button" @click="HandleAddToCartClick" ref="AddToCartButton" class="btn btn-primary">Add To Cart</button>
              <button v-else type="button" disabled class="btn btn-warning">Itinerary Is Not Valid</button>                         
            </div>
            <div class="col-sm-3">
              <button @click="HandleClearItinerary" class="btn btn-warning">Clear Itinerary</button>
            </div>
            <div class="col-sm-3">              
              <button type="button" @click="HandleGoBackToSearch" class="btn btn-info">Change Itinerary</button>
            </div>
            <div class="col-sm-3">
              <div class="input-group mb-3">
                <span class="input-group-text">Itinerary Total ZAR </span>
                <input type="text" readonly :value="GetItineraryTotalPrice" class="form-control">
              </div>
            </div>                    
          </div>

      </div>
    </div>

    <br>

    <div v-for="(Hotel) in Hotels" :key="Hotel.code" class="row">

      <div class="col-sm-12 mb-3">
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <img :src="GetHotelGeneralImage(Hotel.code)" class="img-fluid rounded-start" alt="General Image Of The Hotel">
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h3 @click="HandleHotelNameClick(Hotel.code)" class="card-title hover">{{Hotel.name}} <span class="badge bg-warning">{{Hotel.categoryName}}</span></h3>
                

                <div v-if="QuickAddRooms.length > 0">
                  <div v-for="(arrRR, iLoop) in GetQuickRoomsFromHotelCode(Hotel.code).RoomRates" :key="'rr' + iLoop">

                    <div v-for="RR in arrRR" :key="RR.Room.code + RR.Rate.key">
                      <h5 class="text-success">{{RR.Room.name}} - {{RR.Rate.boardName}} <span class="badge bg-success">ZAR {{RR.Rate.net}}</span></h5>                
                      <h6 class="text-primary">{{RR.Rate.allotment}} Rooms Available</h6>
                      <h6 class="text-dark">{{RR.Rate.adults}} Adults</h6>
                      <h6 class="text-dark">{{RR.Rate.children}} Children</h6>
                      <p v-for="(CP, iIndex) in RR.Rate.cancellationPolicies" :key="iIndex" class="text-danger lead">Cancellation from {{moment(CP.from).format('LLL')}} costing ZAR {{CP.amount}}</p>
                    </div>
                    
                  </div>
                </div>

                

                <!-- <h5 class="text-success">{{Hotel.rooms[0].name}} - {{Hotel.rooms[0].rates[0].boardName}} <span class="badge bg-success">ZAR {{Hotel.rooms[0].rates[0].net}}</span></h5>                
                <h6 class="text-primary">{{Hotel.rooms[0].rates[0].allotment}} Rooms Available</h6>
                <h6 class="text-dark">{{Hotel.rooms[0].rates[0].adults}} Adults</h6>
                <h6 class="text-dark">{{Hotel.rooms[0].rates[0].children}} Children</h6> -->
  
                <h4 class="text-success">Total ZAR: {{GetNetPriceOfQuickRoom(Hotel.code)}}</h4>

                <button @click="HandleQAToItin(Hotel.name, Hotel.code)" v-if="Input_NumberRooms > 1" class="btn btn-success">Add {{Input_NumberRooms}} Rooms To Itinerary</button>
                <button @click="HandleQAToItin(Hotel.name, Hotel.code)" v-else class="btn btn-success">Add To Itinerary</button>

              </div>
            </div>
            <div class="card-footer">

              <div class="accordion" :id="'mainaccordian' + Hotel.code">
                <div class="accordion-item">
                  <h2 class="accordion-header" :id="'headingOne' + Hotel.code">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapsemain' + Hotel.code" aria-expanded="true" aria-controls="collapseOne">More Rooms and Boards</button>
                  </h2>
                  <div :id="'collapsemain' + Hotel.code" class="accordion-collapse collapse" :aria-labelledby="'headingOne' + Hotel.code" :data-bs-parent="'#mainaccordian' + Hotel.code">
                    <div class="accordion-body">
                      
                      <div v-for="(Room, iIndex) in Hotel.rooms" :key="Room.code">

                        <div class="accordion" :id="'ac' + Hotel.code + iIndex">
                          <div class="accordion-item">
                            <h2 class="accordion-header" :id="'h' + Hotel.code + iIndex">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#c' + Hotel.code + iIndex" aria-expanded="true" aria-controls="collapseOne">{{Room.name}} --- <span class="badge bg-success">ZAR {{Room.rates[0].net}}</span></button>
                            </h2>
                            <div :id="'c' + Hotel.code + iIndex" class="accordion-collapse collapse" aria-labelledby="headingOne" :data-bs-parent="'#ac' + Hotel.code + iIndex">
                              <div class="accordion-body">

                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Board</th>
                                      <th>Price</th>
                                      <th>Available Rooms</th>                                      
                                      <th>Adults</th>
                                      <th>Children</th>
                                      <th>View</th>
                                      <th>Add To Itinerary</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="hover" v-for="Rate in Room.rates" :key="Rate.key">
                                      <td>{{Rate.boardName}}</td>
                                      <td>ZAR {{Rate.net}}</td>
                                      <td>{{Rate.allotment}}</td>                                      
                                      <td>{{Rate.adults}}</td>
                                      <td>{{Rate.children}}</td>
                                      <td><button @click="HandleViewCPClick(Rate)" data-bs-toggle="modal" data-bs-target="#modalcptaxandoffer" class="btn btn-sm btn-outline-info">View</button></td>
                                      <td><button @click="HandleAddToItinerary(Hotel.name, Hotel.code, Room.name, Rate)" class="btn btn-sm btn-success">Add To Itinerary</button></td>
                                    </tr>
                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </div>
                        </div>

                      </div>


                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>        
      </div>      

    </div>

    <br>    

    <!--Toast for success messages-->
    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
      <div ref="SuccessToast" id="liveToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">          
          <strong class="me-auto">Success</strong>          
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body text-success">{{SuccessMessage}}</div>        
      </div>
    </div>

    <!--Toast for Error messages-->
    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
      <div ref="ErrorToast" id="liveToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">          
          <strong class="me-auto">Error</strong>          
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body text-danger">{{ErrorMessage}}</div>
      </div>
    </div>

    <!--Cancellation Policy modals-->
    <div class="modal fade" id="modalcptaxandoffer" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{SelectedRate.boardName}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-body mb-3">
                <h5 class="card-title">{{SelectedRate.boardName}} <span class="badge bg-info">Packaging</span></h5>
                <h5 class="card-subtitle mb-2 text-success">ZAR {{SelectedRate.net}}</h5>
                <p class="lead text-primary">{{SelectedRate.allotment}} Rooms Available</p>
                
                <p v-if="SelectedRate.rateType !== 'BOOKABLE'" class="text-warning">Price may change. Add to cart to view final price.</p>
                <p v-for="(CP, iIndex) in SelectedRate.cancellationPolicies" :key="iIndex" class="lead text-danger">Cancellation from {{moment(CP.from).format('LLL')}} costing ZAR {{CP.amount}}</p>
                
                <div v-if="SelectedRate.taxes">
                  <p v-for="(Tax, iIndex) in SelectedRate.taxes.taxes" :key="iIndex">
                    <span v-if="Tax.included" class="text-success">Tax Included: {{Tax.currency}} {{Tax.amount}}</span>
                    <span v-else class="text-secondary">Taxes: {{Tax.currency}} {{Tax.amount}}</span>
                  </p>
                </div>

                <div v-if="SelectedRate.offers">
                  <div v-for="Offer in SelectedRate.offers" :key="Offer.code" class="alert alert-success">{{Offer.name}} {{Offer.amount}}</div>
                </div>

                <p class="text-dark lead">Adults: {{SelectedRate.adults}}</p>
                <p class="text-dark lead">Children: {{SelectedRate.children}}</p>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


    
  </div>
</template>

<script>

import { computed, onMounted, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";
import { UseHotelSearchStore } from "@/stores/HotelSearchStore";
const moment = require("moment");

import { usePlacesAutocomplete, getGeocode } from 'v-use-places-autocomplete'
const NoImagePlaceholder = require('../assets/noimage.png')

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {flatPickr},
    setup(){ 

      const HotelSearchStore = UseHotelSearchStore()

      //toasts
      const SuccessToast = ref(null)
      const SuccessMessage = ref('')
      const ErrorToast = ref(null)      
      const ErrorMessage = ref('')

      const NumberHotels = ref('')      
      const Hotels = ref([])
      const HotelContents = ref([])      
      const HotelBedsImageBaseURL = ref('')

      const arrOccupancies = ref([])
      const Input_NumberRooms = ref(1)

      onMounted(() => {

        //Load stuff from store
        if(HotelSearchStore.HotelResults.length < 1){
          router.push({name: 'home'})
        }

        Hotels.value = HotelSearchStore.HotelResults        
        arrOccupancies.value = HotelSearchStore.SearchData.Occupancies        
        Input_NumberRooms.value = HotelSearchStore.SearchData.NumberRooms

      })

      const GetHotelGeneralImage = (HotelCode) => {        
        
        let ObjHotelContent = HotelSearchStore.HotelContent.find((Obj) => Obj.code === HotelCode)

        if(!ObjHotelContent || !ObjHotelContent.images){
          console.log('here1');
          return NoImagePlaceholder
        }

        let sImageObject = ObjHotelContent.images.find((Obj) => Obj.type.code === "GEN")

        if(sImageObject === undefined){
          console.log('here2');
          return NoImagePlaceholder
        }

        return HotelSearchStore.ImageBase + sImageObject.path
      }
      
      const HandleHotelNameClick = (sHotelCode) => {        
        window.open("/Hotel/" + sHotelCode, "_blank")
      }

      const AddToCartButton = ref(null)

      const HandleAddToCartClick = () => {

        AddToCartButton.value.disabled = true
        AddToCartButton.value.textContent = "Please Wait..."
        
        const frmItinerary = Itinerary.value
        
        axios.post("/api/AddRateToCart", {frmItinerary}).then((Result) => {
          if(Result.data.Success){            
            AddToCartButton.value.disabled = false
            AddToCartButton.value.textContent = "Add To Cart"
            window.open("/cart")
          }else{            
            AddToCartButton.value.disabled = false
            AddToCartButton.value.textContent = "Error"
            ErrorMessage.value = Result.data.Reason
            ErrorToast.value.classList.add('show')
          }          
                  
        }).catch((Error) => {
          console.log(Error)                  
          AddToCartButton.value.disabled = false
          AddToCartButton.value.textContent = "Error"
        })

      }

      const TotalAdults = computed(() => {

        var iTotal = 0

        if(!arrOccupancies.value) return iTotal

        arrOccupancies.value.forEach((O, iLoop) => {

          if((iLoop + 1) > Input_NumberRooms.value) return

          iTotal += O.adults

        })

        return iTotal

      })

      const TotalChilds = computed(() => {

        var iTotal = 0

        if(!arrOccupancies.value) return iTotal

        arrOccupancies.value.forEach((O, iLoop) => {

          if((iLoop + 1) > Input_NumberRooms.value) return

          iTotal += O.children

        })

        return iTotal

      })

      const Itinerary = ref([])

      const HandleAddToItinerary = (sHotelName, sHotelCode, sRoomName, Rate) => {

        //check if hotel is the same
        if(Itinerary.value.length >= 1){
          if(Itinerary.value[0].HotelCode !== sHotelCode){
            ErrorMessage.value = "Only one Hotel per itinerary is allowed. Please clear your itinerary to change Hotel"
            ErrorToast.value.classList.add('show')
            setTimeout(() => {
              ErrorToast.value.classList.remove('show')
            }, 2500);
            return
          }
        }

        Rate.RoomName = sRoomName
        Rate.HotelCode = sHotelCode      
        Rate.HotelName = sHotelName  

        Itinerary.value.push(Rate)

        SuccessMessage.value = "Itinerary Updated!"
        SuccessToast.value.classList.add('show')
        setTimeout(() => {
          SuccessToast.value.classList.remove('show')
        }, 2500);

      }

      const IsItineraryValid = computed(() => {

        var iRequestedAdults = 0
        var iRequestedChilds = 0

        var ItineraryAdults = 0
        var ItineraryChilds = 0

        if(!arrOccupancies.value) return false

        arrOccupancies.value.forEach((O, iLoop) => {

          if((iLoop + 1) > Input_NumberRooms.value) return

          iRequestedAdults += O.adults
          iRequestedChilds += O.children

        })

        Itinerary.value.forEach((I) => {

          ItineraryAdults += I.adults
          ItineraryChilds += I.children

        })

        if(iRequestedAdults !== ItineraryAdults) return false
        if(iRequestedChilds !== ItineraryChilds) return false

        return true

      })

      const HandleClearItinerary = () => {
        Itinerary.value.splice(0)
      }

      const QuickAddRooms = computed(() => {

        if(!arrOccupancies.value) return []

        var QuickRooms = []

        Hotels.value.forEach((Hotel) => {

          var HotelRoomRate = {
            "HotelCode": Hotel.code,
            "RoomRates": []
          }

          //For each hotel, calculate the best room/board combo for each occupancy        
          arrOccupancies.value.slice(0, Input_NumberRooms.value).forEach((Occupancy) => {

            var RoomRates = []
            var bRoomRateFound = false

            Hotel.rooms.forEach((Room) => {

              if(bRoomRateFound) return

              Room.rates.forEach((Rate) => {

                if(bRoomRateFound) return

                if(Rate.adults === Occupancy.adults && Rate.children === Occupancy.children){
                  RoomRates.push({Room, Rate})
                  bRoomRateFound = true
                }

              })


            })

            HotelRoomRate.RoomRates.push(RoomRates)

          })

          QuickRooms.push(HotelRoomRate)

        })
        
        return QuickRooms

      })//end function

      const GetQuickRoomsFromHotelCode = (sHotelCode) => {

        const FoundQR =  QuickAddRooms.value.find((a) => {
          return a.HotelCode === sHotelCode
        })        

        return FoundQR

      }

      const HandleQAToItin = (sHotelName, sHotelCode) => {

        HandleClearItinerary()

        const FoundQR = GetQuickRoomsFromHotelCode(sHotelCode)

        FoundQR.RoomRates.forEach((RoomRate) => {

          RoomRate.forEach((RR) => {
            
            HandleAddToItinerary(sHotelName, sHotelCode, RR.Room.name, RR.Rate)

          })

        })

      }

      const GetNetPriceOfQuickRoom = (sHotelCode) => {

        const FoundQR = GetQuickRoomsFromHotelCode(sHotelCode)

        if(!FoundQR) return 0

        var dTotal = 0.0

        FoundQR.RoomRates.forEach((RoomRate) => {
        
          RoomRate.forEach((RR) => {
            
            dTotal += parseFloat(RR.Rate.net)

          })

        })

        return dTotal.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })

      }

      const ClearForm = () => {
        location.reload()
      }

      const SelectedRate = ref({})

      const HandleViewCPClick = (Rate) => {
        SelectedRate.value = Rate
      }

      const HandleRemoveItemFromItin = (iIndex) => {

        Itinerary.value.splice(iIndex, 1)

      }

      const GetItineraryTotalPrice = computed(() => {
        
        var dTotal = 0

        Itinerary.value.forEach((I) => {
          dTotal += parseFloat(I.net)
        })

        return dTotal

      })

      const HandleGoBackToSearch = () => {

        router.back()

      }
      
      

      return {                       
        Hotels,                
        NumberHotels,
        moment,
        HandleHotelNameClick,                  
        HandleAddToCartClick,             
        TotalAdults,
        TotalChilds,
        HandleAddToItinerary,
        Itinerary,
        IsItineraryValid,
        HandleClearItinerary,
        SuccessToast,
        SuccessMessage,
        ErrorToast,        
        GetQuickRoomsFromHotelCode,
        QuickAddRooms,
        HandleQAToItin,
        AddToCartButton,
        GetNetPriceOfQuickRoom,
        ClearForm,
        SelectedRate,
        HandleViewCPClick,
        HandleRemoveItemFromItin,
        GetHotelGeneralImage,
        Input_NumberRooms,
        ErrorMessage,
        GetItineraryTotalPrice,
        HandleGoBackToSearch
      }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

.suggest-item:hover{
  background: #0B5ED7;
  color: white;
  cursor: pointer;
}

.grid-img{
  width: 100%;
  height: 50vh;
}

.btn-room-type{
  text-align: left !important;
}

.hover:hover{
  color: #0B5ED7;
  cursor: pointer;
}
</style>