<template>
  <div class="table-view">
    <br>
    <div class="card">
      <h5 class="card-header">Filters - <button type="button" @click="ResetFilters" class="btn btn-sm btn-warning">Reset</button></h5>
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-sm-3 mb-3">
            <label>Filter By</label>
            <select v-model="Input_DateFilter" class="form-select border-primary">
              <option disabled selected value="NONE">Please Select</option>
              <option value="CREATION" selected>Date Created</option>
              <option value="CHECKINOUT">Check In - Check Out</option>
            </select>
          </div>
          <div class="col-sm-3 mb-3">
            <label>Date Range</label>            
            <flat-pickr ref="DatePicker" v-model="Input_AccommodationDates" class="form-control border-primary" :config="dateconfig"></flat-pickr>          
          </div>
          <div class="col-sm-3 mb-3">
            <label>Booking Reference</label>
            <input class="form-control" v-model="Input_SearchByBookingRef" type="text">
          </div>
          <div class="col-sm-3 mb-3">
            <label>Client Reference</label>
            <input class="form-control" v-model="Input_SearchByClientRef" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-3 mb-3">
            <label>Pax Name</label>
            <input class="form-control" v-model="Input_SearchByPaxName" type="text">
          </div>
          <div class="col-sm-3 mb-3">
            <label>Hotel Name</label>
            <input class="form-control" v-model="Input_SearchByHotelName" type="text">
          </div>
        </div>
      </div>
    </div>

    <br>

    <div v-if="ErrorMessage" class="alert alert-danger">{{ErrorMessage}}</div>

    <div v-if="Bookings.length > 0" class="card">
      <div class="card-body">
        <table class="table table-hover table-primary">
          <thead>
            <tr>
              <th>Date</th>              
              <th>Booking Ref</th>
              <th>Pax</th>
              <th>Hotel</th>
              <th>Check In - Check Out</th>
              <th>Client Ref</th>
              <th>Amount</th>
              <th>Paid</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="Booking in FilteredBookings" :key="Booking.Reference">
              <td>{{moment(Booking.Date).format('DD MMM yyyy')}}</td>
              <td>{{Booking.Reference}}</td>
              <td>{{Booking.Pax}}</td>
              <td class="hover" @click="HandleHotelNameClick(Booking.HotelCode)">{{Booking.Hotel}}</td>
              <td>{{moment(Booking.CheckIn).format('DD MMM YYYY') + ' - ' + moment(Booking.CheckOut).format('DD MMM YYYY')}}</td>
              <td>{{Booking.ClientRef}}</td>
              <td>ZAR {{Booking.NetCost}}</td>
              <td v-if="Booking.Paid"><button class="btn btn-sm btn-success">Yes</button></td>
              <td v-else><button class="btn btn-sm btn-danger">No</button></td>
              <td><router-link class="btn btn-sm btn-dark" :to="'/booking/' + Booking.Reference">View</router-link></td>              
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>

import { onMounted, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: { flatPickr},
    setup(){

      const ErrorMessage = ref('')      

      const Bookings = ref([])
      const FilteredBookings = ref([])

      const Input_AccommodationDates = ref(null)
      const Input_SearchByClientRef = ref('')
      const Input_SearchByBookingRef = ref('')
      const Input_SearchByPaxName = ref('')
      const Input_SearchByHotelName = ref('')
      const Input_SearchByDestName = ref('')
      const Input_DateFilter = ref('NONE')      

      const dateconfig = ref({
          mode: 'range',          
          dateFormat: 'Y-m-d',
          altFormat: 'd F Y',
          altInput: true
        })

      onMounted(() => {

        UpdateMyBookingsList();

      })

      const UpdateMyBookingsList = (sFilterType, sStart, sEnd) => {

        const frmStartDate = sStart
        const frmEndDate = sEnd
        const frmFilterType = sFilterType        

        axios.post("/api/GetBookings", {
          frmStartDate,
          frmEndDate,
          frmFilterType          
        }).then((Result) => {
          if(Result.data.Success){                        
            Bookings.value = Result.data.Bookings            
            FilteredBookings.value = Result.data.Bookings
          }else{
            ErrorMessage.value = Result.data.Reason
          }
        }).catch((Error) => {
          console.log(Error)
          ErrorMessage.value = "Error retrieving the bookings"
        })

      }      

      const HandleHotelNameClick = (sHotelCode) => {        
        window.open("/Hotel/" + sHotelCode, "_blank")
      }       

      //Filter functions
      watch(Input_DateFilter, () => {

        if(!Input_AccommodationDates.value){
          return
        }

        //Get accommodation dates
        let AccommodationDates = Input_AccommodationDates.value.replace(/\s/g, '').split('to')

        if(AccommodationDates.length === 2){

          const frmStartDate = AccommodationDates[0].toString()
          const frmEndDate = AccommodationDates[1].toString()

          UpdateMyBookingsList(Input_DateFilter.value, frmStartDate, frmEndDate)

        }
            
      })

      watch(Input_AccommodationDates, () => {
        Input_DateFilter.value = "NONE"
      })

      watch(Input_SearchByBookingRef, () => {            
        FilteredBookings.value = Bookings.value.filter((a) => {                 
          return a.Reference.includes(Input_SearchByBookingRef.value)
        })
      })

      watch(Input_SearchByClientRef, () => {            
        FilteredBookings.value = Bookings.value.filter((a) => {                 
          return a.ClientRef.includes(Input_SearchByClientRef.value.toUpperCase())
        })
      })

      watch(Input_SearchByPaxName, () => {            
        FilteredBookings.value = Bookings.value.filter((a) => {                 
          return a.Pax.includes(Input_SearchByPaxName.value.toUpperCase())
        })
      })

      watch(Input_SearchByHotelName, () => {            
        FilteredBookings.value = Bookings.value.filter((a) => {                 
          return a.Hotel.toUpperCase().includes(Input_SearchByHotelName.value.toUpperCase())
        })
      })

      const ResetFilters = () => {
        Input_DateFilter.value = "NONE"
        Input_AccommodationDates.value = ""
        Input_SearchByBookingRef.value = ""
        Input_SearchByClientRef.value = ""
        Input_SearchByPaxName.value = ""
        Input_SearchByHotelName.value = ""              
        UpdateMyBookingsList()
      }

      return{
        Bookings,
        ErrorMessage,
        UpdateMyBookingsList,
        moment,                
        FilteredBookings,
        dateconfig,
        Input_AccommodationDates,
        Input_SearchByClientRef,
        Input_SearchByBookingRef,
        Input_SearchByPaxName,
        Input_SearchByHotelName,        
        Input_DateFilter,                
        HandleHotelNameClick,
        ResetFilters
      }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}
.hover:hover{
  color: #0B5ED7;
  cursor: pointer;
}
</style>