<template>
  <div class="normal-view">
    <br>

    <div v-if="!SearchLoading" class="card">
      <form @submit.prevent="HandleSearch">

        <h5 class="card-header">Hotel Search</h5>

        <div class="card-body">

          <div class="row mb-3">
            <div class="col-sm-4 mb-3">
              <label class="form-label">Find Acommodation Near: <span class="badge bg-success">Powered by Google Maps</span></label>              
              <input ref="InputGoogleSugg" class="form-control" type="text" v-model="query" placeholder="Enter City, Address, Airport etc..." />
              <ul class="list-group">
                <li :class="item.place_id === SelectedPlaceID ? 'bg-success text-white' : ''" @click="HandleSuggestClick(item)" class="list-group-item suggest-item" v-for="item in suggestions" :key="item.place_id" v-text="item.description" />
              </ul>
            </div>
            <div class="col-sm-4 mb-3">
              <label class="form-label">Dates</label>
              <flat-pickr ref="DatePicker" v-model="Input_AccommodationDates" class="form-control" :config="dateconfig"></flat-pickr>
            </div>
            <div class="col-sm-2 mb-3">
              <label class="form-label">Search Radius (KM)</label>
              <select required v-model="Input_SearchRadius" class="form-select">
                <option value="1">1 KM</option>
                <option value="3">3 KM</option>
                <option value="5">5 KM</option>
                <option value="10">10 KM</option>
                <option value="15">15 KM</option>
                <option value="20">20 KM</option>
              </select>
            </div>
            <div class="col-sm-2 mb-3">
              <label class="form-label">Number Of Rooms</label>
              <select required v-model="Input_NumberRooms" class="form-select">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
              </select>
            </div>

          </div>

          <!--Category Filter-->
          <div class="row mb-3">
            
            <div class="col-sm-2">
              <label class="form-label">Minimum Category</label>
              <select v-model="Input_MinCategory" class="form-select">
                <option value="0" selected>Any</option>
                <option value="1">1 Star</option>
                <option value="2">2 Star</option>
                <option value="3">3 Star</option>
                <option value="4">4 Star</option>
                <option value="5">5 Star</option>
              </select>
            </div>

            <div class="col-sm-2">
              <label class="form-label">Maximum Category</label>
              <select v-model="Input_MaxCategory" class="form-select">
                <option value="0" selected>Any</option>
                <option value="1">1 Star</option>
                <option value="2">2 Star</option>
                <option value="3">3 Star</option>
                <option value="4">4 Star</option>
                <option value="5">5 Star</option>
              </select>
            </div>  

          </div>

          <!--Depending on the number of rooms, we loop and show a div element-->

          <div v-for="iNumRooms in parseInt(Input_NumberRooms)" :key="iNumRooms" class="row">
            
            <span class="lead">{{"Room " + iNumRooms}}</span>

            <div class="col-sm-2 mb-3">
              <label class="form-label">Number Of Adults</label>
              <select @change="HandleAdultSelect($event, iNumRooms)" required class="form-select">              
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
              </select>
            </div> 
            
            <div class="col-sm-2 mb-3">
              <label class="form-label">Number Of Children</label>
              <select @change="HandleChildsSelect($event, iNumRooms)" required class="form-select">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>

            <div v-for="iNum in arrOccupancies[iNumRooms - 1].children" :key="iNum" class="col-sm-2 mb-3">
              <label class="form-label">Child {{iNum}} age</label>
              <input @keyup="HandleChildAgeType($event, iNumRooms, iNum)" class="form-control" type="number" min="0" max="18">
            </div>

          </div> 

        </div>

        <div class="card-footer">
          <div class="row">            
            <div class="col-sm-2 mb-3">
              <button type="submit" class="btn btn-primary">Search</button>
            </div>

            <div class="col-sm-2 mb-3">
              <button @click="ClearForm" type="submit" class="btn btn-secondary">Clear Search</button>
            </div>
          </div>
        </div>


      </form>
    </div>  

    <br>

    <div v-if="SearchLoading && !ErrorMessage" class="card text-center">
      <div class="card-header h3 text-primary">Searching... Please stand by</div>
      <div class="card-body">
        <img src="../assets/loading.gif" class="img-fluid" alt="Loading image" />
      </div>
      <div class="card-footer text-muted">Your results will be ready in a moment</div>
    </div>

    <div v-if="ErrorMessage" class="card text-center">
      <div class="card-header h3 text-danger">{{ErrorMessage}}</div>
      <div class="card-footer text-muted"><button type="button" class="btn btn-outline-dark" @click="HandleAdjustParamClick">Change Search Paramters</button></div>
    </div>

    

  </div>
</template>

<script>

import { computed, onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
import { UseHotelSearchStore } from "@/stores/HotelSearchStore";
const moment = require("moment");

import { usePlacesAutocomplete, getGeocode } from 'v-use-places-autocomplete'

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {flatPickr},
    setup(){ 

      const HotelSearchStore = UseHotelSearchStore()

      const Input_AccommodationDates = ref(null) 

      const Input_NumberRooms = ref('1')
      const Input_SearchRadius = ref('3')

      const Input_MinCategory = ref('0')
      const Input_MaxCategory = ref('0')

      const ChildrenAges = ref(['0', '0', '0', '0'])

      const SubmitButton = ref(null) 

      const SearchLoading = ref(false)
      const ErrorMessage = ref('')

      onMounted(() => {

        if(HotelSearchStore.SearchData.SearchQueried){

          InputGoogleSugg.value.placeholder = HotelSearchStore.SearchData.PlaceName
          Input_AccommodationDates.value = HotelSearchStore.SearchData.CheckIn + " to " + HotelSearchStore.SearchData.CheckOut
          Input_SearchRadius.value = HotelSearchStore.SearchData.SearchRadius
          Input_NumberRooms.value = HotelSearchStore.SearchData.NumberRooms
          Input_MinCategory.value = HotelSearchStore.SearchData.MinCategory
          Input_MaxCategory.value = HotelSearchStore.SearchData.MaxCategory
          arrOccupancies.value = HotelSearchStore.SearchData.Occupancies
          arrPaxes.value = HotelSearchStore.SearchData.Paxes
          sLatitude.value = HotelSearchStore.SearchData.Latitude
          sLongitude.value = HotelSearchStore.SearchData.Longitude

        }

      })

      const dateconfig = ref({
        mode: 'range',
        minDate: 'today',
        dateFormat: 'Y-m-d',
        altFormat: 'd F Y',
        altInput: true
      })

      const HandleSearch = () => {

        SearchLoading.value = true

        const frmLatitude = sLatitude.value
        const frmLongitude = sLongitude.value

        let AccommodationDates = Input_AccommodationDates.value.replace(/\s/g, '').split('to')
        const frmStartDate = AccommodationDates[0].toString()
        const frmEndDate = AccommodationDates[1].toString()

        const frmRooms = Input_NumberRooms.value
        const frmOccupancies = arrOccupancies.value.slice(0, parseInt(Input_NumberRooms.value))
        const frmPaxes = arrPaxes.value  
        
        const frmSearchRadius = Input_SearchRadius.value
        
        const frmMinCategory = parseInt(Input_MinCategory.value)
        const frmMaxCategory = parseInt(Input_MaxCategory.value)

        //Add to pinia the stuff
        HotelSearchStore.SearchData.Latitude = sLatitude.value
        HotelSearchStore.SearchData.Longitude = sLongitude.value
        HotelSearchStore.SearchData.CheckIn = AccommodationDates[0].toString()
        HotelSearchStore.SearchData.CheckOut = AccommodationDates[1].toString()
        HotelSearchStore.SearchData.SearchRadius = Input_SearchRadius.value
        HotelSearchStore.SearchData.NumberRooms = Input_NumberRooms.value
        HotelSearchStore.SearchData.Occupancies = arrOccupancies.value.slice(0, parseInt(Input_NumberRooms.value))
        HotelSearchStore.SearchData.Paxes = arrPaxes.value
        HotelSearchStore.SearchData.MinCategory = parseInt(Input_MinCategory.value)
        HotelSearchStore.SearchData.MaxCategory = parseInt(Input_MaxCategory.value)                        

        //Hotel search
        axios.post("/api/SearchHotels", {
          frmLatitude,
          frmLongitude,
          frmStartDate,
          frmEndDate,
          frmRooms,
          frmOccupancies,
          frmPaxes,
          frmSearchRadius,
          frmMinCategory,
          frmMaxCategory
        }).then((Result) => {

          if(Result.data.Success){
            HotelSearchStore.HotelResults = Result.data.Hotels.sort((a, b) => (parseFloat(a.minRate) > parseFloat(b.minRate) ? 1 : -1))            

            //Load hotel content (images)
            let arrHotelCodes = Result.data.Hotels.map((Hotel) => {
              return Hotel.code
            });

            LoadHotelContent(arrHotelCodes)
            HotelSearchStore.SearchData.SearchQueried = true

            router.push({name: 'resultsview'})
            //Push to new page
          }else{            
            ErrorMessage.value = Result.data.Reason
          }

        }).catch((Error) => {
          console.log(Error);
          ErrorMessage.value = "An API Error Has Occured. Please Try Again Later."
        });                

      }//end handleSearch

      const LoadHotelContent = async (arrHotelCodes) => {

        const frmHotelCodes = arrHotelCodes;

        axios.post("/api/GetHotelContentByHotelCodes", {frmHotelCodes}).then((Result) => {
          if(Result.data.Success){
            HotelSearchStore.HotelContent = Result.data.HotelContents
            HotelSearchStore.ImageBase =  Result.data.ImageBaseURL            
          }
        }).catch((Error) => {
          console.log(Error)
        })

      }


      const query = ref('')
      var { suggestions } = usePlacesAutocomplete(query, {
        debounce: 500,
        minLengthAutocomplete: 3,
        apiKey: "AIzaSyByYYdEBTHR2CtYJbWDww6K7jZhHbHEh9Q"
      })
      
      const sLatitude = ref('')
      const sLongitude = ref('')
      const SelectedPlaceID = ref('')

      const InputGoogleSugg = ref(null)

      const HandleSuggestClick = (item) => {

        SelectedPlaceID.value = item.place_id            
        suggestions.value = []
        query.value = ''
        InputGoogleSugg.value.placeholder = item.description
        HotelSearchStore.SearchData.PlaceName = item.description    

        //'https://www.npmjs.com/package/v-use-places-autocomplete'
        getGeocode({placeId: item.place_id}).then((Result) => {          

          sLatitude.value = Result[0].geometry.location.lat()
          sLongitude.value = Result[0].geometry.location.lng()               
        })        
      }   

      const HandleAdjustParamClick = () => {
        ErrorMessage.value = ""
        SearchLoading.value = false
        HotelSearchStore.HotelResults = []
        HotelSearchStore.SearchData = {}
      }

      const arrOccupancies = ref([
        {
        "rooms": 1,
        "adults": 1,
        "children": 0
        },
        {
        "rooms": 1,
        "adults": 1,
        "children": 0
        },
        {
        "rooms": 1,
        "adults": 1,
        "children": 0
        },
        {
        "rooms": 1,
        "adults": 1,
        "children": 0
        },
        {
        "rooms": 1,
        "adults": 1,
        "children": 0
        },
        {
        "rooms": 1,
        "adults": 1,
        "children": 0
        },
        {
        "rooms": 1,
        "adults": 1,
        "children": 0
        },
        {
        "rooms": 1,
        "adults": 1,
        "children": 0
        }
      ])

      const arrPaxes = ref([
        {
          "RoomNumber": 1,
          "Ages": [0, 0, 0, 0]
        },
        {
          "RoomNumber": 1,
          "Ages": [0, 0, 0, 0]
        },
        {
          "RoomNumber": 1,
          "Ages": [0, 0, 0, 0]
        },
        {
          "RoomNumber": 1,
          "Ages": [0, 0, 0, 0]
        },
        {
          "RoomNumber": 1,
          "Ages": [0, 0, 0, 0]
        },
        {
          "RoomNumber": 1,
          "Ages": [0, 0, 0, 0]
        },
        {
          "RoomNumber": 1,
          "Ages": [0, 0, 0, 0]
        },
        {
          "RoomNumber": 1,
          "Ages": [0, 0, 0, 0]
        },
      ])

      const HandleAdultSelect = (e, iRoomNumber) => {

        arrOccupancies.value[iRoomNumber - 1].rooms = 1
        arrOccupancies.value[iRoomNumber - 1].adults = parseInt(e.target.value)

      }

      const HandleChildsSelect = (e, iRoomNumber) => {
        arrOccupancies.value[iRoomNumber - 1].children = parseInt(e.target.value)
      }

      const HandleChildAgeType = (e, iRoomNumber, iPaxNum) => {

        arrPaxes.value[iRoomNumber - 1].RoomNumber = iRoomNumber
        arrPaxes.value[iRoomNumber - 1].Ages[iPaxNum - 1] = parseInt(e.target.value)

      }

      const ClearForm = () => {
        location.reload()
      }  

      return {
        dateconfig,        
        HandleSearch,
        Input_AccommodationDates,
        Input_NumberRooms,              
        query,
        suggestions,
        HandleSuggestClick,
        SelectedPlaceID,
        moment,                
        HandleAdjustParamClick,
        HandleChildAgeType,
        ChildrenAges,
        Input_SearchRadius,                                       
        InputGoogleSugg,
        HandleAdultSelect,
        HandleChildsSelect,
        arrOccupancies,
        ClearForm,
        Input_MinCategory,
        Input_MaxCategory,
        SearchLoading,
        ErrorMessage
      }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

.suggest-item:hover{
  background: #0B5ED7;
  color: white;
  cursor: pointer;
}

.grid-img{
  width: 100%;
  height: 50vh;
}

.btn-room-type{
  text-align: left !important;
}

.hover:hover{
  color: #0B5ED7;
  cursor: pointer;
}
</style>