import { createRouter, createWebHistory } from 'vue-router'

//Authentication
import LoginView from '../views/authentication/LoginView.vue'

//Homes
import HomeView from '../views/HomeView.vue'
import ResultsView from '../views/ResultsView.vue'

//Hotel
import HotelView from '../views/HotelView.vue'

//Cart
import CartView from '../views/CartView.vue'

//Booking
import MyBookingsView from '../views/MyBookingsView.vue'
import BookingView from '../views/BookingView.vue'

//Booking admin
import AdminViewBookingsView from '../views/admin/AdminViewBookingsView.vue'
import AdminViewBookingView from '../views/admin/AdminViewBookingView.vue'

import AdminDashView from '../views/admin/AdminDashView.vue'
import AdminTAsView from '../views/admin/AdminTAsView.vue'

import axios from "axios";

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {title: "Login | A2Z Beds"}
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {title: "Home | A2Z Beds"}
  },
  {
    path: '/Hotel/:id',
    name: 'hotel',
    component: HotelView,
    meta: {title: "Hotel Info | A2Z Beds"}
  },
  {
    path: '/Cart',
    name: 'cart',
    component: CartView,
    meta: {title: "My Cart | A2Z Beds"}
  },
  {
    path: '/bookings',
    name: 'mybookings',
    component: MyBookingsView,
    meta: {title: "My Bookings | A2Z Beds"}
  },
  {
    path: '/booking/:id',
    name: 'booking',
    component: BookingView,
    meta: {title: "Booking | A2Z Beds"}
  },
  {
    path: '/admin-view-bookings',
    name: 'adminviewbookings',
    component: AdminViewBookingsView,
    meta: {title: "Admin Bookings | A2Z Beds"}
  },
  {
    path: '/admin-view-booking/:id',
    name: 'adminviewbooking',
    component: AdminViewBookingView,
    meta: {title: " Admin View Booking | A2Z Beds"}
  },
  {
    path: '/admin',
    name: 'admindash',
    component: AdminDashView,
    meta: {title: "Admin Dashboard | A2Z Beds"}
  },
  {
    path: '/admin-view-tas',
    name: 'adminviewtas',
    component: AdminTAsView,
    meta: {title: "Admin Travel Agency Management | A2Z Beds"}
  },
  {
    path: '/results',
    name: 'resultsview',
    component: ResultsView,
    meta: {title: "Search Results | A2Z Beds"}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  document.title = to.meta.title;
  
  var bResult = false;

  let prom1 = axios.get('/api/IsLoggedIn').then((Result) => {
    if(Result.data.Success){
      bResult = true
    }
  }).catch((Error) => {
    console.log(Error)
  })

  Promise.all([prom1]).then(() => {

    if(to.name === 'login'){

      if(bResult){
        //We are logged in
        next({name: 'home'})
      }else{
        next();
      }
      
    } else if(to.name === 'register'){

      if(bResult){
        next({name: 'home'})
      }else{
        next()
      }

    }else if(to.name === 'forgotpassword'){

      if(bResult){
        next({name: 'home'})
      }else{
        next()
      }

    }else if(!bResult){
      next({name: 'login'})
    }else{
      next()
    }
  })

})


export default router