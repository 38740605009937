<template>
  <div class="normal-view">

    <br>

    <div class="row">

      <div class="col-sm-3 mb-3">
        <div class="card h-100">
          <h5 class="card-header text-success">400</h5>
          <div class="card-body">
            <h5 class="card-title">Bookings This Month</h5>
            <p class="card-text">1500 Booking this year</p>            
          </div>
        </div>
      </div>

      <div class="col-sm-3 mb-3">
        <div class="card h-100">
          <h5 class="card-header text-success">ZAR 50 000</h5>
          <div class="card-body">
            <h5 class="card-title">Profit made this month</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>            
          </div>
        </div>
      </div>

      <div class="col-sm-3 mb-3">
        <div class="card h-100">
          <h5 class="card-header text-success">20</h5>
          <div class="card-body">
            <h5 class="card-title">Sub Agents</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>            
          </div>
        </div>
      </div>

    </div>

    <br>

    <div class="row">

      <div class="col-sm-4 mb-3">
        <div class="card">
          <h5 class="card-header">Booking Management</h5>
          <div class="card-body">
            <h5 class="card-title"><router-link to="/admin-view-bookings" class="btn btn-primary">Go</router-link></h5>
          </div>
        </div>
      </div>

      <div class="col-sm-4 mb-3">
        <div class="card">
          <h5 class="card-header">Travel Agency Management</h5>
          <div class="card-body">
            <h5 class="card-title"><router-link to="/admin-view-tas" class="btn btn-primary">Go</router-link></h5>
          </div>
        </div>
      </div>
    
    </div>

  </div>  
</template>

<script>

import { onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");

export default {
  components: {},
    setup(){

        return{}
    }
}
</script>

<style scoped>

</style>