<template>
  <div class="table-view">
    <br>
    <div class="card">
      <h5 class="card-header"><span class="lead text-danger">Admin Filters</span> - <button type="button" @click="ResetFilters" class="btn btn-sm btn-warning">Reset</button></h5>
      <div class="card-body">

        <div class="row mb-3">
          <div class="col-sm-3 mb-3">
            <label>Travel Agency</label>
            <input v-model="Input_SearchByTAName" type="text" class="form-control" />
          </div>
        </div>
      </div>
    </div>

    <br>

    <div v-if="ErrorMessage" class="alert alert-danger">{{ErrorMessage}}</div>

    <div v-if="TAs.length > 0" class="card">
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Trading As</th>
              <th>Manager Name</th>
              <th>Manager Contact</th>
              <th>Manager Email</th>
              <th>Active</th>
              <th>Date Created</th>
              <th>Agency Credit</th>
              <th>Mark Up %</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr :class="GetTableBgColor(TA.TAActive)" v-for="TA in FilteredTAs" :key="TA._id">

              <td>{{TA.TAName}}</td>
              <td>{{TA.TATradingAs}}</td>
              <td>{{TA.TAManagerName}}</td>  
              <td><a :href="'tel:' + TA.TAManagerContactNumber">{{TA.TAManagerContactNumber}}</a></td>    
              <td><a :href="'mailto:' + TA.TAManagerEmail">{{TA.TAManagerEmail}}</a></td>
              <td v-if="TA.TAActive"><button type="button" class="btn btn-success">Yes</button></td>    
              <td v-else><button type="button" class="btn btn-warning">No</button></td>                               
              <td>{{moment(TA.TADateCreated).format('LLLL')}}</td>
              <td v-if="TA.TAZARCredit">
                <input :value="Number(TA.TAZARCredit).toLocaleString()" readonly type="text" class="form-control">
              </td>
              <td v-else>No Credit</td>
              <td><input :value="TA.TABedsMarkUpPercentage" type="text" readonly class="form-control" placeholder="Markup Percentage"/></td>
              <td><button @click="HandleMarkupClick(TA)" data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn btn-danger" type="button">Edit</button></td>                        
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal for viewing and mark up -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{SelectedTA.TAName}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            
            <form @submit.prevent="HandleChangeTAMarkup">
              <div v-if="MarkupErrorMessage" class="mb-3">
                <div class="alert alert-danger">{{MarkupErrorMessage}}</div>
              </div>
              <div class="mb-3">
                <label class="form-label">New Markup Percentage</label>
                <input min="0" max="100" type="number" step="1" class="form-control" v-model="Input_NewMarkup">
                <div  class="form-text text-danger">This will not change any bookings that are already in their cart</div>
              </div>
              <div class="mb-3">
                <label class="form-label">Change Agency Credit Available</label>
                <input min="0" type="number" step="1" class="form-control" v-model="Input_NewAgencyCredit">                
              </div>
              <button ref="ModalSubmitButton" type="submit" class="btn btn-primary">Confirm</button>
            </form>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-warning" data-bs-dismiss="modal">Close</button>            
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { computed, onMounted, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import TypeaheadInput from 'vue3-typeahead-input';
import 'vue3-typeahead-input/dist/style.css'; //Optional default CSS


export default {
  components: {flatPickr, TypeaheadInput},
    setup(){

      const ErrorMessage = ref('')            
  
      const Input_SearchByTAName = ref('')
      const TAs = ref([])

      const SelectedTA = ref({})

      onMounted(() => {

        LoadTAs()

      })    

      const LoadTAs = () => {

        //Get travel agencies
        axios.get("/api/AdminGetTravelAgencies").then((Result) => {
          if(Result.data.Success){
            TAs.value = Result.data.TAs            
          }
        }).catch((Error) => {
          router.push({path: '/'})
          console.log(Error)
        })

      }

      const GetTableBgColor = (bActive) => {
        if(bActive) return 'table-success'
        return 'table-warning'
      }


      //Filter functions
      const FilteredTAs = computed(() => {

        const Result = TAs.value.filter((a) => {
          return a.TAName.toUpperCase().includes(Input_SearchByTAName.value.toUpperCase())
        })

        return Result

      })

      const ResetFilters = () => {
        Input_SearchByTAName.value = ""        
      }

      const HandleMarkupClick = (TA) => {
        Input_NewMarkup.value = TA.TABedsMarkUpPercentage
        Input_NewAgencyCredit.value = TA.TAZARCredit
        SelectedTA.value = TA
      }

      const HandleChangeTAMarkup = () => {        

        if(Input_NewMarkup.value === undefined) return
        if(Input_NewAgencyCredit.value === undefined) return

        if(Input_NewMarkup.value === "") return
        if(Input_NewAgencyCredit.value === "") return   

        ModalSubmitButton.value.disabled = true
        ModalSubmitButton.value.textContent = "Please Wait..."

        var frmNewMarkup, frmNewAgencyCredit

        try{
          frmNewMarkup = parseInt(Input_NewMarkup.value)
          frmNewAgencyCredit = parseFloat(Input_NewAgencyCredit.value)
        }catch(E){
          MarkupErrorMessage.value = "Error. Markup must be an Integer between 1 and 100"
          return
        }

        axios.post("/api/AdminUpdateTAMarkupAndCredit/" + SelectedTA.value._id, {
          frmNewMarkup,
          frmNewAgencyCredit
        }).then((Result) => {
          if(Result.data.Success){
            ModalSubmitButton.value.disabled = false
            ModalSubmitButton.value.textContent = "Done"
            setTimeout(() => {
              ModalSubmitButton.value.textContent = "Confirm"
            }, 2500);
            LoadTAs()
          }else{
            MarkupErrorMessage.value = Result.data.Reason
            ModalSubmitButton.value.disabled = false
            ModalSubmitButton.value.textContent = "Error"
          }
        }).catch((Error) => {
          console.log(Error)
          MarkupErrorMessage.value = "API Error. Please contact admin"        
          ModalSubmitButton.value.textContent = "Error"
        })


      }

      const MarkupErrorMessage = ref('')
      const Input_NewMarkup = ref('')
      const Input_NewAgencyCredit = ref('')
      const ModalSubmitButton = ref(null)

      return{      
        ErrorMessage,        
        moment,                        
        GetTableBgColor,
        ResetFilters,
        Input_SearchByTAName,
        TAs,
        SelectedTA,
        HandleMarkupClick,
        Input_NewMarkup,
        MarkupErrorMessage,
        Input_NewAgencyCredit,
        ModalSubmitButton,
        HandleChangeTAMarkup,
        FilteredTAs
      }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}
.hover:hover{
  color: #0B5ED7;
  cursor: pointer;
}
</style>