<template>
  <div class="normal-view">
    <br>
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">My Cart</h3>
        <div class="row">
          <div class="col-sm-6">
            <Countdown @timeElapsed="HandleTimerCompleted" mainColor="#0D6EFD" :showHours="false" :showDays="false" v-if="TimerEndTime" :deadlineISO="TimerEndTime" />
          </div>
          <div class="col-sm-6">
            <p class="card-text text-primary lead">Please complete the booking before the timer expires to guarantee the rate</p>            
          </div>
        </div>                
      </div>
    </div>
    <br>

    <div v-if="NoCartMessage">
      <div class="alert alert-danger">{{NoCartMessage}}</div>
    </div>

    <div v-if="CartData">

      <div class="card mb-3">
        <div class="row g-0">
          <div class="col-md-4">
            <img @click="HandleHotelNameClick(CartData.code)" :src="ImageURL" class="img-fluid rounded-start hover" alt="Hotel Image">
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 @click="HandleHotelNameClick(CartData.code)" class="card-title hover">{{CartData.name}} <span class="badge bg-warning">{{CartData.categoryName}}</span></h5>
              <div class="row">
                <div class="col-md-6">
                  <h3 class="card-text text-success">Check In:</h3>
                </div>
                <div class="col-md-6">
                  <h3 v-if="CartData.checkIn" class="card-text text-success">{{moment(CartData.checkIn).format('DD MMMM yyyy')}}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h3 class="card-text text-danger">Check Out:</h3>
                </div>
                <div class="col-md-6">
                  <h3 v-if="CartData.checkOut" class="card-text text-danger">{{moment(CartData.checkOut).format('DD MMMM yyyy')}}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h3 class="card-text text-primary">Total Net Price:</h3>
                </div>
                <div class="col-md-6">
                  <h3 class="card-text text-primary">ZAR {{CartData.totalNet}}</h3>
                </div>
              </div>
              <br>
              <p v-if="HotelDetail" class="card-text">{{HotelDetail.address.content}}</p>          
              <p class="card-text"><small class="text-muted">{{CartData.destinationName}}, {{CartData.zoneName}}</small></p>              
            </div>
          </div>
        </div>
      </div>

      <div v-for="Room in CartData.rooms" :key="Room.code" class="card border-dark mb-3">
        <div class="card-header h3">{{Room.name}}</div>
        <div class="card-body">

          <div v-for="Rate in Room.rates" :key="Rate.rateKey" class="card border-dark mb-3">
            <div class="card-header h4"><span class="text-success">{{Rate.rooms}} X </span>{{Rate.boardName}} <span v-if="Rate.packaging" class="badge bg-info">Packaging</span></div>
            <div class="card-body text-primary">
              <h4 class="card-title">Rate Net Cost: ZAR {{Rate.net}}</h4>
              <p class="card-text text-success">{{Rate.rateComments}}</p>
              <p class="card-text text-primary">{{Rate.rooms}} X Room</p>
              <p class="card-text text-primary">{{Rate.adults}} X Adults</p>
              <p class="card-text text-primary">{{Rate.children}} X Children</p>
              <p v-for="Cancellation in Rate.cancellationPolicies" :key="Cancellation.amount" class="card-text text-danger">Cancellation from {{moment(Cancellation.from).format('LLLL')}} costing ZAR {{Cancellation.amount}}</p>
            </div>
          </div>

        </div>
      </div>

      <div class="card mb-3">
        <h3 class="card-header bg-warning">Confirm Booking</h3>
        <div class="card-body">
          <h5 class="card-title text-danger">Please make sure the booking information provided is as correct as possible. Any cancellations or changes made to the booking after confirmation might be chargeable</h5>
          <br>
          <form @submit.prevent="HandleSubmit">
            <div class="row mb-3">
              <div class="col-sm-4">
                <label class="form-label">Lead passenger name</label>
                <input required v-model="Input_LeadPaxName" type="text" class="form-control"> 
              </div>
              <div class="col-sm-4">
                <label class="form-label">Lead passenger surname</label>
                <input required v-model="Input_LeadPaxSurname" type="text" class="form-control"> 
              </div>
              <div class="col-sm-4">
                <label class="form-label">Agency reference</label>
                <input required v-model="Input_AgencyRef" type="text" class="form-control"> 
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12">
                <label class="form-label">Remarks to Hotel</label>
                <textarea v-model="Input_Remarks" class="form-control" rows="3"></textarea>
              </div>
            </div>

            <!-- <div class="row mb-3">
              <div class="col-sm-12">
                <div class="form-check">
                  <input v-model="Input_PayNow" class="form-check-input" type="checkbox">
                  <label class="form-check-label">Pay Now?</label>
                </div>
              </div>
            </div> -->

            <div v-if="CartData.paymentDataRequired">
              <div class="row mb-3">
                <div class="col-sm-12">
                  <div class="alert alert-danger">This booking requires payment to be made</div>
                </div>                
              </div>
            </div>

            <div v-if="Input_PayNow">
              <div class="row mb-3">
                <div class="col-sm-4">
                  <label class="form-label">Card Type</label>           
                  <select v-model="Input_CCType" class="form-select">
                    <option value="AMEX">American Express</option>
                    <option value="DINERS">Diners</option>
                    <option value="MASTERCARD">Mastercard</option>
                    <option value="VISA">Visa</option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <label class="form-label">Card Number</label>
                  <input :required="CartData.paymentDataRequired" v-model="Input_CCNumber" type="text" class="form-control">
                </div>
                <div class="col-sm-2">
                  <label class="form-label">Card CVC</label>
                  <input :required="CartData.paymentDataRequired" v-model="Input_CCC_VC" type="text" class="form-control">
                </div>
                <div class="col-sm-2">
                  <label class="form-label">Card Expiry</label>
                  <input :required="CartData.paymentDataRequired" v-model="Input_CCExpiry" type="text" class="form-control">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4">
                  <label class="form-label">Name On Card</label>
                  <input :required="CartData.paymentDataRequired" v-model="Input_CC_CardName" type="text" class="form-control">
                </div>
                <div class="col-sm-4">
                  <label class="form-label">Contact Email</label>
                  <input :required="CartData.paymentDataRequired" v-model="Input_Email" type="text" class="form-control">
                </div>
                <div class="col-sm-4">
                  <label class="form-label">Contact Number</label>
                  <input :required="CartData.paymentDataRequired" v-model="Input_Contact" type="text" class="form-control">
                </div>
              </div> 
            </div>
                                        
            <div class="row">
              <div class="col-sm-12">
                <button ref="SubmitButton" type="submit" class="btn btn-success btn-lg">Confirm Booking ></button>
              </div>
            </div>
            
          </form>

          <br>

          <div v-if="ErrorMessage" class="row mb-3">
              <div class="col-sm-12">
                <div class="alert alert-danger">{{ErrorMessage}}</div>
              </div>              
            </div>                  
        </div>
      </div>

    </div>

    
    
    
    <div v-if="NoCartMessage" class="alert alert-danger">{{NoCartMessage}}</div>
  </div>
  
</template>

<script>

import { onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");
const NoImagePlaceholder = require('../assets/noimage.png')
import {Countdown} from 'vue3-flip-countdown'

export default {
  components: {Countdown},
    setup(){

      const ErrorMessage = ref('')

      const CartData = ref(null)
      const HotelDetail = ref(null)
      const NoCartMessage = ref('')   
      const ImageURL = ref(NoImagePlaceholder)   
      const TimerEndTime = ref(null)   

      const SubmitButton = ref(null)

      //Inputs
      const Input_LeadPaxName = ref('')
      const Input_LeadPaxSurname = ref('')
      const Input_AgencyRef = ref('')
      const Input_Remarks = ref('')
      const Input_CCType = ref('')
      const Input_CCNumber = ref('')
      const Input_CCC_VC = ref('')
      const Input_CCExpiry = ref('')
      const Input_CC_CardName = ref('')
      const Input_Email = ref('')
      const Input_Contact = ref('')
      const Input_PayNow = ref(false)


      onMounted(() => {

        let Prom1 = axios.get("/api/GetMyCart").then((Result) => {
          if(Result.data.Success){
            CartData.value = Result.data.CartItem
            
            if(!Result.data.CanBook){
              NoCartMessage.value = "Error. You do not have enough available credit to continue with this booking. Please contact support to increase your credit allowance.";
              
              setTimeout(() => {
                SubmitButton.value.disabled = true
              }, 1000);

              
            }

          }else{
            console.log(Result.data.Reason)
            NoCartMessage.value = Result.data.Reason
          }
        }).catch((Error) => {
          console.log(Error)
          NoCartMessage.value = "Your cart could not be loaded. Please contact support for more information"
        })

        //Only run if there is something in the cart
        Prom1.then(() => {

          if(CartData.value){
            axios.get("/api/GetHotelContentByHotelCode/" + CartData.value.code).then((Result) => {
              if(Result.data.Success){                
                HotelDetail.value = Result.data.HotelDetail
                console.log(HotelDetail.value);
                let sImageObject = HotelDetail.value.images.find((Obj) => Obj.type.code === "GEN")                                
                if(sImageObject){
                  ImageURL.value = Result.data.ImageBaseURL + sImageObject.path
                }
                
                TimerEndTime.value = moment(new Date()).add(10, 'm').toDate()
              }else{
                console.log(Result.data.Reason)
              }
            }).catch((Error) => {
              console.log(Error)
            })
          }
          
        })//end promise


      })//End on mount

      const HandleTimerCompleted = () => {         
        window.location.reload()    
      }

      const HandleHotelNameClick = (sHotelCode) => {        
        window.open("/Hotel/" + sHotelCode, "_blank")
      }

      const HandleSubmit = () => {

        ErrorMessage.value = ''
        SubmitButton.value.disabled = true
        SubmitButton.value.textContent = "Please Wait..."

        const frmLeadPaxName = Input_LeadPaxName.value
        const frmLeadPaxSurname = Input_LeadPaxSurname.value
        const frmAgencyRef = Input_AgencyRef.value
        const frmRemarks = Input_Remarks.value
        const frmPayNow = Input_PayNow.value
        const frmCCType = Input_CCType.value
        const frmCCNumber = Input_CCNumber.value
        const frmCCC_VC = Input_CCC_VC.value
        const frmCCExpiry = Input_CCExpiry.value
        const frmCC_CardName = Input_CC_CardName.value
        const frmEmail = Input_Email.value
        const frmContact = Input_Contact.value

        axios.post("/api/ConfirmBooking", {          
          frmLeadPaxName,
          frmLeadPaxSurname,
          frmAgencyRef,
          frmRemarks,
          frmPayNow,
          frmCCType,
          frmCCNumber,
          frmCCC_VC,
          frmCCExpiry,
          frmCC_CardName,
          frmEmail,
          frmContact
        }).then((Result) => {
          if(Result.data.Success){            
            router.push({ path: '/booking/' + Result.data.BookingRef })            
          }else{
            ErrorMessage.value = Result.data.Reason
            SubmitButton.value.textContent = "Error!"
          }          
          SubmitButton.value.disabled = false
        }).catch((Error) => {
          ErrorMessage.value = "An error occured. Please try again later or contact support"
          console.log(Error)
          SubmitButton.value.textContent = "Error"
        })


      }

      return{
        CartData,
        NoCartMessage,
        HotelDetail,
        ImageURL,
        TimerEndTime,
        HandleTimerCompleted,
        moment,
        HandleHotelNameClick,
        Input_LeadPaxName,
        Input_LeadPaxSurname,
        Input_AgencyRef,
        Input_Remarks,
        Input_CCType,
        Input_CCNumber,
        Input_CCC_VC,
        Input_CCExpiry,
        Input_CC_CardName,
        Input_Email,
        Input_Contact,
        HandleSubmit,
        ErrorMessage,
        Input_PayNow,
        SubmitButton
      }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

.hover:hover{
  color: #0B5ED7;
  cursor: pointer;
}
</style>