<template>
  <div class="normal-view">
    <br v-if="ErrorMessage">
    <div v-if="ErrorMessage" class="alert alert-danger">{{ErrorMessage}}</div>
    <br>

    <div v-if="Booking" class="card">
      <h5 class="card-header lead text-danger">Admin Booking Managment <button ref="GenPDFButton" @click="HandleGenPDF" class="btn btn-success">Generate PDF Voucher</button></h5>
      <div class="card-body">
        <form @submit.prevent="HandleUpdateBooking">

          <div class="row mb-3">
            <div class="col-sm-6">
              <label class="form-label">Lead Passenger Name</label>
              <input v-model="Input_LeadPaxName" type="text" class="form-control">       
            </div>
            <div class="col-sm-6">
              <label class="form-label">Lead Passenger Surname</label>
              <input v-model="Input_LeadPaxSurname" type="text" class="form-control">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-12">
              <label class="form-label">Client Reference</label>
              <input v-model="Input_ClientReference" type="text" class="form-control"> 
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-12">
              <label class="form-label">Remarks To Hotel</label>
              <textarea v-model="Input_Remarks" class="form-control"></textarea>
            </div>
          </div>
          <button ref="UpdateButton" type="submit" class="btn btn-primary">Update</button>
        </form>
      </div>
      <div class="card-footer">        
        <button v-if="moment(Booking.hotel.checkIn) >= moment() " ref="CancelButton" @click="HandleCancelBooking" type="button" class="btn btn-danger">Cancel Booking ></button>
        <div v-else class="alert alert-info">Booking can not be cancelled after Check In date</div>
      </div>
    </div>

    <div v-if="!UpToDate">
      <br>
      <div class="alert alert-warning">We are currently experiencing issues with the retrieval of this booking. Please assume that this booking and it's status is <strong>NOT</strong> up to date. Please check again  later</div>
    </div>

    <div v-if="TravelAgency && UserDetails">
      <br>
      <div class="card">
        <h3 class="card-header text-primary">{{TravelAgency.TAName}} <strong>trading as</strong> {{TravelAgency.TATradingAs}}</h3>
        <div class="card-body">
          <h5 class="card-title text-danger">Booking made by: {{UserDetails.Name}} | {{UserDetails.Email}}</h5>
          <br>

          <div class="row mb-3">
            <div class="col-md-6">
              <h4 class="card-text text-secondary">Manager Name</h4>
            </div>
            <div class="col-md-6">
              <h4 class="card-text text-secondary">{{TravelAgency.TAManagerName}}</h4>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-6">
              <h4 class="card-text text-secondary">Manager Contact</h4>
            </div>
            <div class="col-md-6">
              <h4 class="card-text text-secondary">{{TravelAgency.TAManagerContactNumber}}</h4>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-6">
              <h4 class="card-text text-secondary">Manager Email</h4>
            </div>
            <div class="col-md-6">
              <h4 class="card-text text-secondary">{{TravelAgency.TAManagerEmail}}</h4>
            </div>
          </div>

        </div>
      </div>
    </div>


    <br>
    <div v-if="Booking" class="card">
      <h5 class="card-header"><span class="badge bg-primary">{{moment(Booking.creationDate).format('DD MMMM YYYY')}}</span> <span class="badge bg-success" v-if="Booking.status === 'CONFIRMED'">{{Booking.status}}</span><span class="badge bg-warning" v-else>{{Booking.status}}</span></h5>
      <div class="card-body">
        <h2 class="hover card-title" @click="HandleHotelNameClick(Booking.hotel.code)">{{Booking.hotel.name}} <span class="badge bg-warning">{{Booking.hotel.categoryName}}</span></h2>
        <h4 class="border-primary">Voucher Reference: <span class="text-success">{{Booking.reference}}</span></h4>
        <h4 v-if="Booking.cancellationReference" class="border-primary text-danger">Cancellation Reference: <span class="text-danger">{{Booking.cancellationReference}}</span></h4> 
        <p class="card-text">{{Booking.hotel.destinationName}}</p>        
        <div class="row">
          <div class="col-md-6">
            <h3 class="card-text text-secondary">Check In:</h3>
          </div>
          <div class="col-md-6">
            <h3 class="card-text text-secondary">{{moment(Booking.hotel.checkIn).format('DD MMMM yyyy')}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h3 class="card-text text-dark">Check Out:</h3>
          </div>
          <div class="col-md-6">
            <h3 class="card-text text-dark">{{moment(Booking.hotel.checkOut).format('DD MMMM yyyy')}}</h3>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-6">
            <h3 class="card-text text-primary">Base Cost: </h3>
          </div>
          <div class="col-md-6">
            <h3 class="card-text text-primary">ZAR {{Booking.totalNet}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h3 class="card-text text-dark">Markup %: </h3>
          </div>
          <div class="col-md-6">
            <h3 class="card-text text-dark">{{DBBooking.BookingMarkUpPercentage}} %</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h3 class="card-text text-success">Final Cost: </h3>
          </div>
          <div class="col-md-6">
            <h3 class="card-text text-success">ZAR {{DBBooking.BookingFinalCost}}</h3>
          </div>
        </div>

        <div v-if="Booking.pendingAmount">
          <br>
          <div class="row">
            <div class="col sm-12">
              <div class="alert alert-danger">Pending amount of: ZAR {{Booking.pendingAmount}}</div>
            </div>          
          </div>
        </div>
        

        <br>
        
        <div v-for="Room in Booking.hotel.rooms" :key="Room.code" class="row">
          <div class="card border-dark mb-3">
            <div class="card-header h3">{{Room.name}} <span class="badge bg-success" v-if="Room.status === 'CONFIRMED'">{{Room.status}}</span><span class="badge bg-warning" v-else>{{Room.status}}</span></div>
            <div class="card-body">

              <h3 v-if="Room.supplierReference" class="text-success">Hotel Confirmation Number: {{Room.supplierReference}}</h3>
              <h3 class="text-primary">Service is confirmed but hotel confirmation number is not available. Please check later.</h3>

              <h3>Pax Information</h3>
        
              <div v-for="(P, iLoop) in Room.paxes" :key="iLoop">
                <p v-if="P.type === 'AD'"><span class="badge bg-primary">{{"Room: " + P.roomId + " - Adult"}}</span></p>
                <p v-else><span class="badge bg-secondary">{{"Room: " + P.roomId +  " - Child" + " - Age: " + P.age}}</span></p>
              </div>

              <div v-for="Rate in Room.rates" :key="Rate.rateKey" class="card border-primary mb-3">
                <div class="card-header h4"><span class="text-success">{{Rate.rooms}} X </span>{{Rate.boardName}} <span v-if="Rate.packaging" class="badge bg-info">Packaging</span></div>
                <div class="card-body text-primary">
                  <h3 class="card-title">Rate Net Cost: ZAR {{Rate.net}}</h3>                                    
                  <p class="card-text text-success">{{Rate.rateComments}}</p>
                  <h4>Lead passenger: {{Booking.holder.name}} {{Booking.holder.surname}}</h4>                  
                  <p class="card-text text-primary">{{Rate.adults}} X Adults</p>
                  <p v-if="Rate.children" class="card-text text-primary">{{Rate.children}} X Children</p>
                  <p v-for="Cancellation in Rate.cancellationPolicies" :key="Cancellation.amount" class="card-text text-danger">Cancellation from {{moment(Cancellation.from).format('LLLL')}} costing ZAR {{Cancellation.amount}}</p>
                </div>
              </div>

            </div>            
          </div>
        </div>      

        <div v-if="Hotel">

          <h2 class="text-secondary">Hotel Contact Information</h2>

          <div class="row mb-3">
            <div class="col-sm-12"><h3 class="lead text-primary">Contact Email: <a :href="'mailto:' + Hotel.email">{{Hotel.email}}</a></h3></div>        
          </div>

          <div class="row mb-3">
            <div class="col-sm-12"><h3 class="lead text-primary">Website: <a target="_blank" :href="Hotel.web">{{Hotel.web}}</a></h3></div>        
          </div>

          <div class="row mb-3">
            <div class="col-sm-12"><h3 class="lead text-primary">Address: {{Hotel.address.content}} | {{Hotel.address.street}}</h3></div>        
          </div>

          <div class="row mb-3">
            <div class="col-sm-12"><h3 class="lead text-primary">Last updated: {{moment(Hotel.lastUpdate).fromNow()}} - {{moment(Hotel.lastUpdate).format('LLLL')}}</h3></div>        
          </div>

          <div class="row mb-3">
            <div v-for="Issue in Hotel.issues" :key="Issue.issueCode" class="alert alert-danger">{{Issue.issueType}} - {{Issue.description.content}}</div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-12">
              <table class="table table-primary">
                <thead>
                  <tr>
                    <th scope="col">Phone Type</th>
                    <th scope="col">Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="Phone in Hotel.phones" :key="Phone.phoneType">
                    <td>{{Phone.phoneType}}</td>
                    <td><a :href="'tel:' + Phone.phoneNumber">{{Phone.phoneNumber}}</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      
      </div>
      <div class="card-footer text-muted">Invoiced By: {{Booking.invoiceCompany.company}} ({{Booking.invoiceCompany.registrationNumber}})</div>
    </div>
    
  </div>
</template>

<script>

import { onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");
import { useRoute } from 'vue-router';

export default {
  components: {},
    setup(){

      let sBookingRef = useRoute().params.id
      const Booking = ref(null)
      const DBBooking = ref(null)
      const TravelAgency = ref(null)
      const UserDetails = ref(null)
      const UpToDate = ref(true)      
      const Hotel = ref(null)
      const ErrorMessage = ref('')

      const Input_LeadPaxName = ref('')
      const Input_LeadPaxSurname = ref('')
      const Input_ClientReference = ref('')
      const Input_Remarks = ref('')

      const UpdateButton = ref(null)
      const CancelButton = ref(null)

      onMounted(() => {
        LoadBooking()    
      })

      const HandleHotelNameClick = (sHotelCode) => {        
        window.open("/Hotel/" + sHotelCode, "_blank")
      }

      const GetHotelContent = (sHotelCode) => {
        //Hotel content
        axios.get("/api/GetHotelContentByHotelCode/" + sHotelCode).then((Result) => {
          if(Result.data.Success){              
            Hotel.value = Result.data.HotelDetail
            //console.log(Result.data.HotelDetail)
          }else{

          }
        }).catch((Error) => {
          console.log(Error)
        })
      }

      const LoadBooking = () => {
        axios.get("/api/AdminGetBooking/" + sBookingRef).then((Result) => {
          if(Result.data.Success){
            console.log(Result.data)
            Booking.value = Result.data.Booking   
            DBBooking.value = Result.data.DBBooking  
            TravelAgency.value = Result.data.TA
            UserDetails.value = Result.data.UserDetails
            UpToDate.value = Result.data.UpToDate                       
            GetHotelContent(Result.data.Booking.hotel.code);            
            Input_LeadPaxName.value = Booking.value.holder.name
            Input_LeadPaxSurname.value = Booking.value.holder.surname
            Input_ClientReference.value = Booking.value.clientReference
            Input_Remarks.value = Booking.value.remark
          }else{
            ErrorMessage.value = Result.data.Reason
          }
        }).catch((Error) => {
          console.log(Error)
          ErrorMessage.value = "An API Error occured. Please contact support and try again later."
        })
      }

      const HandleUpdateBooking = () => {

        ErrorMessage.value = ""

        UpdateButton.value.disabled = true
        UpdateButton.value.textContent = 'Please wait...'
        
        const frmLeadPaxName = Input_LeadPaxName.value
        const frmLeadPaxSurname = Input_LeadPaxSurname.value
        const frmClientReference = Input_ClientReference.value
        const frmRemarks = Input_Remarks.value

        axios.post("/api/AdminUpdateBooking/" + sBookingRef, {
          frmLeadPaxName,
          frmLeadPaxSurname,
          frmClientReference,
          frmRemarks,
        }).then((Result) => {
          if(Result.data.Success){
            UpdateButton.value.textContent = 'Done!'
            Booking.value = Result.data.Booking
            setTimeout(() => {
              UpdateButton.value.textContent = 'Update'
            }, 2500);
          }else{
            UpdateButton.value.textContent = 'ERROR'
            ErrorMessage.value = Result.data.Reason
          }
          UpdateButton.value.disabled = false          
        }).catch((Error) => {
          console.log(Error)
          UpdateButton.value.disabled = false
          UpdateButton.value.textContent = 'ERROR'
        })

      }

      const HandleCancelBooking = () => {

        ErrorMessage.value = ""

        CancelButton.value.disabled = true
        CancelButton.value.textContent = 'Please wait...'

        axios.post("/api/AdminCancelBooking/" + sBookingRef).then((Result) => {          
          if(Result.data.Success){
            CancelButton.value.textContent = 'Done!'
            Booking.value = Result.data.Booking
            setTimeout(() => {
              CancelButton.value.textContent = 'Cancel Booking >'
            }, 2500);
          }else{
            ErrorMessage.value = Result.data.Reason
            CancelButton.value.textContent = 'ERROR'
          }
          CancelButton.value.disabled = false          
        }).catch((Error) => {
          console.log(Error)
          CancelButton.value.disabled = false
          CancelButton.value.textContent = 'ERROR'
        })



      }

      const GenPDFButton = ref(null)

      const HandleGenPDF = () => {

        GenPDFButton.value.disabled = true;
        GenPDFButton.value.textContent = "Please Wait..."

        axios.get("/api/GeneratePDFVoucher/" + sBookingRef).then((Result) => {
          if(Result.data.Success){
            saveAs(new Blob([dataURItoBlob(Result.data.PDFData)], { type: 'application/pdf' }), Result.data.FileName, { autoBOM: false });
          }else{
            ErrorMessage.value = Result.data.Reason
          }
          GenPDFButton.value.disabled = false;
          GenPDFButton.value.textContent = "Generate PDF"
        }).catch((Error) => {
          console.log(Error)
          ErrorMessage.value = "Error generating PDF"
          GenPDFButton.value.textContent = "Error"
        })

        }

        //arigato stackoverflow
      const dataURItoBlob = (dataURI) =>  {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;

      }

        return{
          Booking,
          Hotel,
          ErrorMessage,
          HandleHotelNameClick,
          HandleUpdateBooking,
          HandleCancelBooking,
          moment,
          Input_LeadPaxName,
          Input_LeadPaxSurname,
          Input_ClientReference,
          Input_Remarks,
          UpdateButton,        
          CancelButton,
          UpToDate,
          DBBooking,
          TravelAgency,
          UserDetails,
          HandleGenPDF,
          GenPDFButton 
        }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}
.hover:hover{
  color: #0B5ED7;
  cursor: pointer;
}
</style>