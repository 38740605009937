<template>
  <div class="normal-view auth-form">
    <div v-if="Hotel" class="card">
      <h3 class="card-header">{{Hotel.name.content}} <span class="badge bg-warning">{{Hotel.category.description.content}}</span></h3>
      <div class="card-body">                      

        <p v-if="Hotel.chain" class="card-text text-secondary">{{Hotel.chain.description.content}} - ({{Hotel.accommodationType.typeDescription}})</p>
        <p v-for="Segment in Hotel.segments" :key="Segment.code" class="text-success">{{Segment.description.content}}</p>
        <h5 class="card-title lead text-primary">{{Hotel.address.content}} | {{Hotel.zone.description.content}}, {{Hotel.destination.name.content}}, {{Hotel.state.name}}, {{Hotel.country.description.content}}</h5>        
        <p class="card-text">{{Hotel.description.content}}</p>   
      
        <div class="row mb-3">

          <div class="col-sm-6 mb-3">
            <h3 class="lead text-primary">Facilities/Amenities</h3>
            <div class="accordion" id="accordianFacilities">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFac">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFac" aria-expanded="true" aria-controls="collapseOne">Click To View</button>
                </h2>
                <div id="collapseFac" class="accordion-collapse collapse" aria-labelledby="headingFac" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="form-check">
                      <div v-for="Facility in Hotel.facilities" :key="Facility.code">
                        <input class="form-check-input" type="checkbox" checked>
                        <label class="form-check-label">{{Facility.description.content}}</label>
                      </div>          
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 

          <div class="col-sm-6 mb-3">
            <h3 class="lead text-primary">Boards</h3>
            <ul class="list-group">
              <li v-for="Board in Hotel.boards" :key="Board.code" class="list-group-item">{{Board.description.content}}</li>
            </ul>
          </div>

        </div>

        <div class="row mb-3">
          <div class="col-sm-12"><h3 class="lead text-primary">Contact Email: <a :href="'mailto:' + Hotel.email">{{Hotel.email}}</a></h3></div>        
        </div>

        <div class="row mb-3">
          <div class="col-sm-12"><h3 class="lead text-primary">Website: <a target="_blank" :href="'https://' + Hotel.web">{{Hotel.web}}</a></h3></div>        
        </div>

        <div class="row mb-3">
          <div class="col-sm-12"><h3 class="lead text-primary">Last updated: {{moment(Hotel.lastUpdate).fromNow()}} - {{moment(Hotel.lastUpdate).format('LLLL')}}</h3></div>        
        </div>

        <div class="row mb-3">
          <div v-for="Issue in Hotel.issues" :key="Issue.issueCode" class="alert alert-danger">{{Issue.issueType}} - {{Issue.description.content}}</div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-12">
            <table class="table table-primary">
              <thead>
                <tr>
                  <th scope="col">Phone Type</th>
                  <th scope="col">Number</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="Phone in Hotel.phones" :key="Phone.phoneType">
                  <td>{{Phone.phoneType}}</td>
                  <td><a :href="'tel:' + Phone.phoneNumber">{{Phone.phoneNumber}}</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row mb-3">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <div class="row">
                  <div class="col-sm-6">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">View rooms</button>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">Search</span>
                      <input v-model="SearchRoomText" type="text" class="form-control form-control-lg" placeholder="Search Rooms" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                  </div>
                </div>                
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <table class="table table-primary">
                    <thead>
                      <tr>
                        <th scope="col">Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Min Pax</th>
                        <th scope="col">Max Pax</th>
                        <th scope="col">Max CHD</th>
                        <th scope="col">View Room</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="Room in FilteredRooms" :key="Room.roomCode">
                        <td>{{Room.roomCode}}</td>
                        <td>{{Room.description}}</td>
                        <td>{{Room.minPax}}</td>
                        <td>{{Room.maxPax}}</td>
                        <td>{{Room.maxChildren}}</td>
                        <td v-if="Hotel.images.find((Obj) => Obj.roomCode === Room.roomCode)"><button @click="HandleViewRoomClick(Room)" type="button" class="w-100 btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">View</button></td>
                        <td v-else><button disabled type="button" class="w-100 btn btn-sm btn-outline-danger">No images</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>        

        <div class="row mb-3">
          <div v-for="Image in CarouselImages" :key="Image.order" class="col-sm-4">
            <img :src="GetHotelImage(Image.path)" class="img-thumbnail" alt="HotelImage" />
          </div>
        </div>        

        <div class="row mb-3">
          <h3 class="lead text-primary">Nearby points of interest</h3>
          <table class="table table-primary">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Distance (meters)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="Point in Hotel.interestPoints" :key="Point.poiName">
                <td>{{Point.poiName}}</td>
                <td>{{Point.distance}}</td>                
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row mb-3">
          <h3 class="lead text-primary">Google Maps</h3>
        </div>

        <div class="row mb-3 border-primary">
          <GoogleMap api-key="AIzaSyByYYdEBTHR2CtYJbWDww6K7jZhHbHEh9Q" style="width: 100%; height: 500px" :center="center" :zoom="17">
            <Marker :options="markerOptions" />
            <CustomMarker :options="{ position: center, anchorPoint: 'BOTTOM_CENTER' }">
              <div style="text-align: center">
                <img src="../assets/hotelmarker.png" width="100" height="100" />
              </div>
            </CustomMarker>
          </GoogleMap>
        </div>

        

      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ModalTitle}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="SelectedRoomImages.length < 1">
              <div class="alert alert-warning">Unfortunately, no images are stored for this room</div>
            </div>
            <div class="mb-3" v-for="Image in SelectedRoomImages" :key="'roomimage' + Image.order">
              <img :src="GetHotelImage(Image.path)" class="img-fluid" alt="HotelImage" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import { onMounted, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");
import { useRoute } from 'vue-router';
import { GoogleMap, Marker, CustomMarker } from "vue3-google-map";

export default {
  components: {GoogleMap, Marker, CustomMarker},
    setup(){

        const Hotel = ref(null)
        let sHotelCode = useRoute().params.id
        const FilteredRooms = ref(null)
        const SearchRoomText = ref('')
        const CarouselImages = ref(null)
        const HotelBedsImageBaseURL = ref('')
        const ModalTitle = ref('')
        const ModalImagePaths = ref([])
        const SelectedRoomImages = ref([])

        const center = ref({})
        const markerOptions = ref({})
      
        onMounted(() => {
          
          axios.get("/api/GetHotelContentByHotelCode/" + sHotelCode).then((Result) => {
            if(Result.data.Success){              
              Hotel.value = Result.data.HotelDetail
              HotelBedsImageBaseURL.value = Result.data.ImageBaseURL
              FilteredRooms.value = Result.data.HotelDetail.rooms
              Hotel.value.images.slice(20).forEach((I) => {
                console.log(I);
              })
              CarouselImages.value = Hotel.value.images.slice(20).filter((Obj) => Obj.type.code === "GEN" || Obj.type.code === "RES" || Obj.type.code === "HAB")                         
              center.value = {lat: Hotel.value.coordinates.latitude, lng: Hotel.value.coordinates.longitude}
              markerOptions.value = { position: center, label: "H", title: Hotel.value.name.content }
            }else{

            }
          }).catch((Error) => {
            console.log(Error)
          })

          watch(SearchRoomText, () => {

            if(SearchRoomText.value === ''){
              FilteredRooms.value = Hotel.value.rooms
            }

            FilteredRooms.value = Hotel.value.rooms.filter((a) => {
              return a.description.toUpperCase().includes(SearchRoomText.value.toUpperCase())
            })//.slice can be added

          })

        })

        const GetHotelImage = (sPath) => {                  
          return HotelBedsImageBaseURL.value + sPath
        }

        const HandleViewRoomClick = (Room) => {          
          SelectedRoomImages.value = Hotel.value.images.filter((Obj) => Obj.type.code === "HAB" && Obj.roomCode === Room.roomCode)
          ModalTitle.value = Room.description
        }
        
        return{
          Hotel,
          SearchRoomText,
          FilteredRooms,
          CarouselImages,
          GetHotelImage,
          ModalTitle,
          ModalImagePaths,
          SelectedRoomImages,
          HandleViewRoomClick,
          moment,
          center,
          markerOptions
        }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}
</style>